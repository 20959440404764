import { createReducer, on } from '@ngrx/store';
import { CartActions } from './cart.actions';

export const cartFeatureKey = 'cart';

export interface State {
  data: any;
  params: any;
  paymentMethods: any;
  selectableGifts: any;
  status: string;
}

export const initialState: State = {
  data: null,
  params: null,
  paymentMethods: null,
  selectableGifts: null,
  status: 'pending',
};

export const reducer = createReducer(
  initialState,
  on(CartActions.loadCartSuccess, (state, action) => {
    if (action.data.bagDetails.appliedRewardPoints) {
      sessionStorage.setItem('reward_points', 'applied');
    }
    return {
      ...state,
      data: action.data,
      status: 'success',
      params: {
        ...state.params,
        promo_code: action.data?.promoCode,
        use_reward_points: action.data?.bagDetails?.appliedRewardPoints
          ? true
          : false,
        store_pickup: action.data?.bagDetails?.storePickup,
        delivery_address_id: action.data?.deliveryAddress?.id,
        removePromo: false
      },
    };
  }),
  on(CartActions.updateCart, (state, action) => {
    if (action.params.use_reward_points === false) {
      sessionStorage.removeItem('reward_points');
    }
    return {
      ...state,
      status: 'updating',
      params: {
        ...state.params,
        ...action.params,
      },
    };
  }),
  on(CartActions.loadPaymentsSuccess, (state, action) => {
    return {
      ...state,
      paymentMethods: action.data,
    };
  }),
  on(CartActions.checkout, (state, action) => {
    return {
      ...state,
      params: {
        ...state.params,
        ...action.params,
      },
    };
  }),
  on(CartActions.loadCartFailure, (state, action) => {
    return {
      ...state,
      status: 'error',
    };
  }),
  on(CartActions.loadSelectableGiftsSuccess, (state, action) => {
    return {
      ...state,
      selectableGifts: action.data,
    };
  }),
  on(CartActions.removeCartData, (state, action) => {
    return {
      ...state,
      data: null,
      params: null,
      paymentMethods: null,
      selectableGifts: null,
    };
  })
);
