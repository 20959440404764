import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../app.store';
import { cartFeatureKey } from './cart.reducer';
import { cart } from 'src/app/core/interface/cart';

const getCart = createFeatureSelector<AppState>(cartFeatureKey);

export const getCartData = createSelector(
    getCart,
    (state: any) => {
        return state.data;
    }
)

export const getCartParam = createSelector(
    getCart,
    (state: any) => {
        return state.params;
    }
)

export const getCartCount = createSelector(
    getCart,
    (state: any) => {
        if (state.data) {
            return state.data.cartItems.length;
        }
    }
)

export const getCartStatus = createSelector(
    getCart,
    (state: any) => {
        if (state.data) {
            return state.status;
        }
    }
)

export const getPaymentMethods = createSelector(
    getCart,
    (state: any) => {
        const payments = state?.paymentMethods?.filter(
            function (element: any, index: any, array: any) {
                if (
                    element.value === 'mypay' ||
                    element.value === 'esewa' ||
                    element.value === 'khalti' ||
                    element.value === 'card' ||
                    element.value === 'cod'
                ) {
                    return true;
                }
                return false;
            }
        )
        return payments
    }
)

export const getSelectableGifts = createSelector(
    getCart,
    (state: any) => state.selectableGifts
)